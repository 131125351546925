console.log('👋');
// Caching Elements
const theBody = document.body;
const menuItems = [...document.querySelectorAll('.header .menu-item')];

const navToggle = () => {
    const burger = document.querySelector('.burger');
    const close = [...document.querySelectorAll('.nav__close, .menu-item')];
    const nav = document.querySelector('.nav');
    const navItems = nav.querySelectorAll('.menu-item');
    const tl = new TimelineMax({
        paused: true,
        onReverseComplete: reverseFunction,
    });

    tl.set(theBody, { className: '+=open' })
        .to(nav, 0.6, { transform: 'none', ease: Expo.easeOut })
        .staggerFromTo(
            navItems,
            0.6,
            { y: 48 },
            { y: 0, ease: Back.easeOut.config(2) },
            '0.05',
            0.1
        );

    burger.addEventListener('click', () => {
        tl.play();
    });

    close.forEach(item => {
        item.addEventListener('click', () => {
            tl.reverse();
        });
    });

    function reverseFunction() {
        theBody.classList.remove('open');
    }
};

if (window.innerWidth < 1080) {
    navToggle();
}

document.addEventListener('swup:contentReplaced', () => {
    menuItems.forEach(item => {
        // 1. remove active class from all links
        item.classList.remove('current-menu-item');
        // 2. go through all links and add active class if href == current URL
        if (window.location.href == item.getAttribute('href')) {
            item.classList.add('current-menu-item');
        }
    });
});

document.addEventListener('lazybeforeunveil', e => {
    const bg = e.target.getAttribute('data-bg');
    if (bg) {
        e.target.style.backgroundImage = `url(${bg})`;
    }
});

// const run = () => {
//     imagesLoaded(document.querySelectorAll('img'), () => {
//         setTimeout(() => {
//             theBody.style.opacity = '0';
//             const loading = document.querySelector('.loading-screen');
//             TweenMax.to(loading, 0.7, {
//                 autoAlpha: 0,
//                 ease: Power4.easeOut,
//                 onComplete: function() {
//                     loading.classList.remove('loading');
//                     theBody.style.opacity = '1';
//                 },
//             });
//         }, 1000);
//     });
// };

// in case the document is already rendered
// if (document.readyState != 'loading') run();
// // modern browsers
// else if (document.addEventListener)
//     document.addEventListener('DOMContentLoaded', run);
// // IE <= 8
// else
//     document.attachEvent('onreadystatechange', function() {
//         if (document.readyState == 'complete') run();
//     });
